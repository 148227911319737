.division {
  display: flex;
  flex-direction: column;
  font-size: 3em;
  text-align: center;
  min-width: 3em;
  padding: 0.125em;
}

.division__line {
  border: 1px solid black;
  margin: 0.125em;
  background-color: black;
}

.division-refactor__action {
  display: flex;
  flex-direction: column;
  justify-content: center;
}